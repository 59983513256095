import { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectingTable from "components/Table/SelectingTable";
import { Toast, UIButton, UISelect, UISubmit } from "components";
import { Form, Formik } from "formik";
import { PIN } from "./PIN";
import {
  getEmployeeInTimeClock,
  getPolicy,
  getSchedules,
  getTimeCheck,
  updateEmployeeInTimeClock,
} from "api";
import { managerErrors } from "utils/functions";

export const Watches = ({ employeeSelected, canChange }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const [politics, setPolitics] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [dataEmployees, setDataEmployees] = useState([]);
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    const fetchPolicy = async () => {
      try {
        const { data } = await getPolicy();
        const politics = data.map((policy) => ({
          value: policy._id,
          label: policy.name,
        }));
        setPolitics(politics);
      } catch (err) {
        console.error("Error getting:", err);
      }
    };

    const fetchSchedule = async () => {
      try {
        const { data } = await getSchedules();
        const schedules = data.map((schedule) => ({
          value: schedule._id,
          label: schedule.name,
        }));
        setSchedules(schedules);
      } catch (err) {
        console.error("Error getting:", err);
      }
    };

    const fetchTime = async () => {
      try {
        const { data } = await getTimeCheck();
        const datTable = data.map((obj) => ({
          id: obj?._id,
          clock: obj?.name,
          description: obj?.description,
          type: obj?.type,
          isActive:
            obj?.isActive === true ? t("employee:Si") : t("employee:No"),
        }));
        setDataEmployees(datTable);
      } catch (err) {
        console.error("Error getting:", err);
      }
    };

    const fetch = async () => {
      try {
        const { data } = await getEmployeeInTimeClock(employeeSelected);
        console.log(data);
        setInitialValues(data);
        setSelected(data?.timeClocks !== undefined ? data?.timeClocks : []);
      } catch (err) {
        console.error("Error getting:", err);
      }
    };

    fetchPolicy();
    fetchSchedule();
    fetchTime();
    fetch();
  }, []);

  const headCells = [
    {
      id: "clock",
      numeric: false,
      disablePadding: true,
      label: t("employee:RelojChecador"),
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: t("employee:DescripcionRelojChecador"),
    },
    {
      id: "type",
      numeric: false,
      disablePadding: true,
      label: t("employee:Tipo"),
    },
    {
      id: "isActive",
      numeric: false,
      disablePadding: true,
      label: t("employee:isActive"),
    },
  ];

  const handleSubmit = async (values) => {
    const nuevoValues = {
      ...values,
      timeClocks: selected,
      employee: employeeSelected,
      isTemporal: false,
    };
    try {
      if (nuevoValues?._id) {
        const { data } = await updateEmployeeInTimeClock(
          nuevoValues?._id,
          nuevoValues
        );
        setInitialValues(data);
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      }
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <Typography variant="h5" mb={2}>
        {t("employee:TimeClock")}
      </Typography>
      <Box>
        <Formik
          initialValues={
            initialValues !== null && initialValues?._id !== undefined
              ? initialValues
              : { timeClocks: [], policy: "", schedule: "" }
          }
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <Form id="" autoComplete="off">
              <Grid container spacing={2}>
                <Grid item={true} xs={12} md={4}>
                  <UISelect
                    formik={formik}
                    label={t("employee:policy")}
                    name={"policy"}
                    options={politics}
                    disabled={!canChange || formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12} md={4}>
                  <UISelect
                    formik={formik}
                    label={t("employee:HorarioLaboral")}
                    name={"schedule"}
                    options={schedules}
                    disabled={!canChange || formik.isSubmitting}
                  />
                </Grid>
              </Grid>
              {initialValues?._id !== undefined &&
                initialValues?.timeClocks !== undefined &&
                initialValues?.timeClocks.length > 0 &&
                canChange && (
                  <Stack
                    mt={2}
                    direction={{ xs: "column", sm: "row" }}
                    spacing={{ xs: 1, sm: 1, md: 1 }}
                    justifyContent="flex-end"
                  >
                    <UIButton
                      label={t("employee:AsignarPIN")}
                      onClick={() => setOpen(true)}
                      sx={{}}
                    />
                  </Stack>
                )}
              <Box mt={2}>
                <SelectingTable
                  headCells={headCells}
                  rows={dataEmployees}
                  selected={selected}
                  setSelected={setSelected}
                  name={t("employee:RelojChecador")}
                  perPage={5}
                  canPermissions={canChange}
                />
              </Box>
              {canChange && <UISubmit formik={formik} />}
            </Form>
          )}
        </Formik>
      </Box>
      <PIN open={open} setOpen={setOpen} id={initialValues?._id} />
    </>
  );
};
