import { DialogContent, DialogTitle, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { FormDocument } from "./FormDocument";
import { useSelector } from "react-redux";
import { UIButton, Toast, UIModal } from "components";
import { profileSelect } from "@redux/slices/profile";
import { saveDocuments, updateDocuments } from "api";

export const ModalDocument = ({
  open,
  setOpen,
  newData,
  setNewData,
  document = null,
  canAdd,
  canChange,
}) => {
  const { employeeSelected } = useSelector(profileSelect);

  const { t } = useTranslation();

  const handleSubmit = async (values, { resetForm }) => {
    console.log("Values: ", values);
    try {
      if (values?._id) {
        delete values.updatedAt;
        delete values.createdAt;
        const { data } = await updateDocuments(values, values._id);
        setNewData(!newData);
        resetForm();
        setOpen(false);
      } else {
        const { data } = await saveDocuments(values);
        setNewData(!newData);
        resetForm();
        setOpen(false);
      }
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: { err },
      });
      console.error("Error submitting form:", err);
    }
  };

  return (
    <UIModal open={open} setOpen={setOpen}>
      <DialogTitle>{t("Cargar documento")}</DialogTitle>
      <Formik
        initialValues={
          !document
            ? {
                employee: employeeSelected._id,
                name: "",
                labels: [],
                description: "",
                filePath: "",
              }
            : document
        }
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form autoComplete="off">
            <DialogContent>
              <FormDocument
                formik={formik}
                srcPath={document?.filePath ? document.filePath : ""}
              />
            </DialogContent>
            <Stack
              mt={2}
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
            >
              {((canAdd && !formik?.values?._id) ||
                (canChange && formik?.values?._id)) && (
                <UIButton
                  type="submit"
                  label={
                    formik?.values?._id
                      ? t("general:Actualizar")
                      : t("general:Guardar")
                  }
                  loading={formik.isSubmitting}
                  sx={{}}
                />
              )}
              <UIButton
                onClick={() => {
                  setOpen(false);
                }}
                label={t("Cancel")}
                disabled={formik.isSubmitting}
                sx={{}}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </UIModal>
  );
};
