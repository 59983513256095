import { cerezaClient } from "../adapters";

const GLOBAL = "/v1/glob";

export function getStorage(id) {
  return cerezaClient.get(`/v1/int/tenant/storage/size/${id}`);
}

export function getCountries(selectors = true) {
  if (selectors) {
    return cerezaClient.get(`${GLOBAL}/country?selectors=true`);
  } else {
    return cerezaClient.get(`${GLOBAL}/country`);
  }
}

export function getLanguages(selectors = false) {
  if (selectors) {
    return cerezaClient.get(`${GLOBAL}/language?selectors=true`);
  } else {
    return cerezaClient.get(`${GLOBAL}/language`);
  }
}

export function saveLanguages(data) {
  return cerezaClient.post(`${GLOBAL}/language`, data);
}

export function updateLanguages(data, id) {
  return cerezaClient.put(`${GLOBAL}/language/${id}`, data);
}

export function getBaseLanguages() {
  return cerezaClient.get(`${GLOBAL}/base/language`);
}

export function getSkills(param) {
  if (param === "selectors") {
    return cerezaClient.get(`${GLOBAL}/skill?selectors=true`);
  } else if (param?.id === "id") {
    return cerezaClient.get(`${GLOBAL}/skill?id=${param.value}`);
  } else {
    return cerezaClient.get(`${GLOBAL}/skill`);
  }
}

export function getCollages() {
  return cerezaClient.get(`${GLOBAL}/collages`);
}

export function saveCollages(data) {
  return cerezaClient.post(`${GLOBAL}/collages`, data);
}

export function getCurrencies(currencies = false) {
  if (currencies) {
    return cerezaClient.get(`${GLOBAL}/currency?selectors=true`);
  } else {
    return cerezaClient.get(`${GLOBAL}/currency`);
  }
}

export function saveCurrencies(data) {
  return cerezaClient.post(`${GLOBAL}/currency`, data);
}

export function updateCurrencies(data, id) {
  return cerezaClient.put(`${GLOBAL}/currency/${id}`, data);
}

export function getBaseCurrencies() {
  return cerezaClient.get(`${GLOBAL}/base/currency`);
}

export function getSearchAll(keyword) {
  return cerezaClient.get(`/v1/search/all?keyword=${keyword}`);
}

export function getTimeZones(param) {
  if (param === "selectors") {
    return cerezaClient.get(`${GLOBAL}/time-zone?selectors=true`);
  } else if (param?.id === "id") {
    return cerezaClient.get(`${GLOBAL}/time-zone?id=${param.value}`);
  } else {
    return cerezaClient.get(`${GLOBAL}/time-zone`);
  }
}
