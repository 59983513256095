import {
  CalendarWidget,
  Coworkers,
  DarkMode,
  UpcomingHolidays,
  Weather,
} from "organizationModules/Home/Dashboard/components";
import { useCountries } from "hooks";

export const KeyIndicators = () => {
  useCountries();

  return (
    <>
      <div className="gridWidgets">
        <div className="colegas">
          <Coworkers />
        </div>
        <div className="calendar">
          <CalendarWidget />
        </div>
        <div className="holiday">
          <UpcomingHolidays />
        </div>
        <div className="clima">
          <Weather />
        </div>
        <div className="darkMode">
          <DarkMode />
        </div>
      </div>
    </>
  );
};
