import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { profileSelect } from "@redux/slices/profile";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  styled,
} from "@mui/material";
import {
  Close as CloseIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FilePreview, Toast, UIButton } from "components";
import { useRef, useState } from "react";
import { patchFacialRecognition, saveFacialRecognition } from "api/employee";
import * as Yup from "yup";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };
  });
};

export const ModalFacialCapture = ({
  open,
  setOpen,
  photo = null,
  appearance,
  setUpdateData,
  canAdd,
  canChange,
}) => {
  const { employeeSelected } = useSelector(profileSelect);
  const { t } = useTranslation();
  const [src, setSrc] = useState(null);
  const [typeUpload, setTypeUpload] = useState("one");
  const [photos, setPhotos] = useState([]);
  const inputFile = useRef(null);

  const facialRecognitionSchema = Yup.object().shape({
    photo: Yup.string().required(t("employee:NoPhotoCaptured")),
  });

  const onSubmit = async (values, { resetForm }) => {
    if (typeUpload === "one") {
      try {
        await facialRecognitionSchema.validate(values, {
          abortEarly: false,
          context: values,
        });

        try {
          if (values._id) {
            await patchFacialRecognition(values);
          } else {
            await saveFacialRecognition(values);
          }

          resetForm();
          setUpdateData(Math.random());
          HandleCloseModal();

          Toast.fire({
            icon: "success",
            title: t("employee:SuccessfullyUpdatedEmployee"),
          });
        } catch (err) {
          Toast.fire({
            icon: "error",
            title: { err },
          });
          console.log("Error submittimg form: ", err);
        }
      } catch (e) {
        setOpen(false);
        Toast.fire({
          icon: "error",
          title: e.errors.join("; "),
        });
      }
    } else {
      for (let i = 0; i < photos.length; i++) {
        const uploadPhoto = {
          employee: employeeSelected._id,
          photo: photos[i],
          name: appearance,
        };

        try {
          await saveFacialRecognition(uploadPhoto);

          Toast.fire({
            icon: "success",
            title: t("employee:SuccessfullyUpdatedEmployee"),
          });
        } catch (err) {
          Toast.fire({
            icon: "error",
            title: { err },
          });
          console.log("Error submittimg form: ", err);
        }
      }

      resetForm();
      setUpdateData(Math.random());
      HandleCloseModal();
    }
  };

  const HandleCloseModal = () => {
    setPhotos([]);
    setTypeUpload("one");
    setOpen(false);
  };

  const OnClickTypeUpload = () => {
    if (typeUpload === "one") {
      setTypeUpload("multiple");
    } else {
      setTypeUpload("one");
    }
  };

  const LoadPhotos = async (files) => {
    const arrayPhotos = Array.from(files).map((e) => e);
    const listPhotos = [...photos];

    for (let i = 0; i < arrayPhotos.length; i++) {
      let photob64 = await getBase64(arrayPhotos[i]).then((result) => {
        return result;
      }).catch((e) => {
        console.log(e);
      });

      listPhotos.push(photob64);
    }

    setPhotos(listPhotos);
  };

  const OpenModalFile = () => {
    inputFile.current.click();
  };

  return (
    <BootstrapDialog
      fullWidth={true}
      onClose={HandleCloseModal}
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        {typeUpload === "one"
          ? t("employee:FaceCapture")
          : t("employee:UploadMultipleFile")}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={HandleCloseModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Formik
          initialValues={!photo
            ? {
              employee: employeeSelected._id,
              photo: "",
              name: appearance,
            }
            : photo}
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form autoComplete="off">
              {typeUpload === "one"
                ? (
                  <FilePreview
                    formik={formik}
                    name={"photo"}
                    src={src}
                    setSrc={setSrc}
                    open={open}
                    setOpen={setOpen}
                    srcPath={photo?.filePath ? photo.filePath : ""}
                    lblBtnUpdate={t("employee:SelectPhoto")}
                    disabled={formik.isSubmitting}
                    acceptFile="image/jpeg"
                  />
                )
                : (
                  <Stack spacing={2}>
                    <Box>
                      <UIButton
                        label={t("employee:SelectFiles")}
                        onClick={OpenModalFile}
                        sx={{}}
                        disabled={formik.isSubmitting}
                      />
                      <input
                        name="files"
                        type="file"
                        multiple
                        accept="image/*"
                        ref={inputFile}
                        hidden
                        onChange={(event) => {
                          LoadPhotos(event.currentTarget.files);
                        }}
                      />
                    </Box>
                    <ImageList
                      variant="quilted"
                      cols={3}
                      rowHeight={164}
                    >
                      {photos.map((photo, index) => (
                        <ImageListItem
                          key={`${index}-${photo}`}
                        >
                          <img
                            srcSet={`${photo}`}
                            src={`${photo}?w=164&h=164&fit=crop&auto=format`}
                          />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton
                                sx={{ color: "rgba(255, 255, 255, 0.75)" }}
                                onClick={() => {
                                  let listPhotos = [...photos];
                                  listPhotos.splice(index, 1);
                                  setPhotos(listPhotos);
                                }}
                                disabled={formik.isSubmitting}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            }
                          >
                          </ImageListItemBar>
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Stack>
                )}
              <Stack
                mt={2}
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 1, md: 1 }}
              >
                <UIButton
                  type="submit"
                  label={formik?.values?._id
                    ? t("general:Actualizar")
                    : t("general:Guardar")}
                  loading={formik.isSubmitting}
                  sx={{}}
                />
                {!formik.values._id &&
                  (
                    <UIButton
                      onClick={OnClickTypeUpload}
                      sx={{}}
                      label={typeUpload === "one"
                        ? t("employee:UploadMultipleFile")
                        : t("employee:SelectAFile")}
                      disabled={formik.isSubmitting}
                    />
                  )}
                <UIButton
                  onClick={HandleCloseModal}
                  label={t("Cancel")}
                  disabled={formik.isSubmitting}
                  sx={{}}
                />
              </Stack>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </BootstrapDialog>
  );
};
