import { TimeClock as OrgTimeClock } from "organizationModules/Employees/Profiles/TimeClock";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const TimeClock = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgTimeClock application="employee" user={{ employeeSelected: user }} />
  );
};
