import { Compensation as OrgCompensation } from "organizationModules/Employees/Profiles/Compensation";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const Compensation = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgCompensation application="employee" user={{ employeeSelected: user }} />
  );
};
