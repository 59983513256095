import { useEffect } from "react";
import TemplateGridUI from "utils/Template";
import { Bilding } from "components/Bilding";
import { Navigate, Route, Routes } from "react-router-dom";
import {
  ACCOUNT,
  HOME,
  LERNING_CENTER,
  MEDICAL,
  PERFORMANCE_CENTER,
  PROFILE,
  PROFILE_MODULE,
  RESOURCES,
  SHIFT_ATTENDANCE,
  SPECIAL_ROUTES,
  TOOLS,
} from "./routes";
import { authUserSelect } from "@redux/slices/authUser";
import { uiSelect } from "@redux/slices/ui";
import { useSelector } from "react-redux";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { createTheme, ThemeProvider } from "@mui/material";

import dayjs from "dayjs";
import "dayjs/locale/es";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/pt";
import "./App.css";

export const unrestrictedRoutes = [];

const App = () => {
  const { accessToken, keycloakProfile, permissions } =
    useSelector(authUserSelect);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { mainColor, mode } = useSelector(parameterizationSelect);
  const { preferences } = useSelector(uiSelect);

  const theme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mainColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            zIndex: 1059,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            zIndex: 1059,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            zIndex: 1060,
          },
        },
      },
    },
  });

  const routesGenerator = (module) => {
    return module.map(({ route, component }, index) => {
      if (
        keycloakProfile?.is_superuser ||
        permissions?.paths.includes(route) ||
        unrestrictedRoutes.includes(route)
      ) {
        return <Route key={index} path={route} element={component} />;
      }
    });
  };

  const routesModule = (modules) => {
    let routes = [];

    Object.keys(modules).forEach((key) => {
      const module = modules[key];

      if (module.hasOwnProperty("sections") && module.sections.length) {
        routes = routes.concat(routesGenerator(module.sections));
      } else {
        routes = routes.concat(routesGenerator([module]));
      }
    });

    return routes;
  };

  useEffect(() => {
    const locale = preferences?.language?.abb;
    switch (locale) {
      case "es":
        return dayjs.locale("es");
      case "en":
        return dayjs.locale("en");
      case "fr":
        return dayjs.locale("fr");
      case "pt":
        return dayjs.locale("pt");
      default:
        return dayjs.locale("es");
    }
  }, [preferences?.language?.abb]);

  return (
    <ThemeProvider theme={theme}>
      {accessToken !== null ? (
        <TemplateGridUI>
          <Routes>
            {keycloakProfile?.is_superuser && !tenant?.wizard?.completed ? (
              <Route path="*" element={<Navigate to="/wizard" />} />
            ) : keycloakProfile?.is_superuser ||
              permissions?.apps?.employees === true ? (
              <>
                <Route path="/" element={<Bilding />} />
                {routesGenerator(HOME)}
                {/* {routesGenerator(ACCOUNT)} */}
                {routesGenerator(PROFILE)}
                {/* {routesGenerator(SHIFT_ATTENDANCE)} */}
                {/* {routesGenerator(LERNING_CENTER)} */}
                {/* {routesGenerator(PERFORMANCE_CENTER)} */}
                {/* {routesGenerator(RESOURCES)} */}
                {/* {routesGenerator(MEDICAL)} */}
                {/* {routesGenerator(TOOLS)} */}
                {routesGenerator(SPECIAL_ROUTES)}
                {routesModule(PROFILE_MODULE)}
              </>
            ) : (
              <Route path="*" element={<Navigate to="/auth/not" />} />
            )}
          </Routes>
        </TemplateGridUI>
      ) : (
        <Routes>
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      )}
    </ThemeProvider>
  );
};

export default App;
