import { useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ProfileTabs, TitleBox, Toast, UIButton, UIPaper } from "components";
import { Box, Stack, Typography } from "@mui/material";
import { profileSelect } from "@redux/slices/profile";
import { deleteReferences, saveReferences, updateReferences } from "api";
import { FormAcademicHistory } from "./FormAcademicHistory";
import isEqual from "lodash/isEqual";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useAccess } from "hooks";
import dayjs from "dayjs";
import { pathEmployee } from "helpers";

export const AcademyHistory = (
  { application = "organization", user = null },
) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [newData, setNewData] = useState(false);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    pathEmployee(application, "/profile/competencies/academy-history"),
    application,
  );
  const { canAdd: canAddAcademy } = HasPermissions(
    pathEmployee(application, "/profile/competencies/academy"),
    application,
  );

  const handleSubmit = async (values, { resetForm }) => {
    const updatedValues = { ...values };

    try {
      if (values?._id) {
        await updateReferences(
          updatedValues,
          employeeSelected._id,
          "academicBG",
          values._id,
        );
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      } else {
        await saveReferences(updatedValues, employeeSelected._id, "academicBG");
        setNewData(!newData);
        resetForm();
        Toast.fire({
          icon: "success",
          title: t("employee:SuccessfullyUpdatedEmployee"),
        });
      }
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  const deleteItem = async (formik) => {
    try {
      await deleteReferences(
        employeeSelected._id,
        "academicBG",
        formik.values._id,
      );
      setNewData(!newData);
      formik.setValues(formik.initialValues);
      formik.resetForm();
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: `${err.response.data.message}`,
      });
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="competencies" application={application} />
      <br />
      <UIPaper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
          />
          <Typography variant="h5">
            {t("employee:Historialacadémico")}
          </Typography>
          <br />
          <Formik
            initialValues={{
              level: "",
              fieldOfStudy: "",
              institution: "",
              endDate: dayjs().format("YYYY-MM-DD"),
              file: "",
            }}
            //   validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormAcademicHistory
                  formik={formik}
                  newData={newData}
                  canAdd={canAdd}
                  canChange={canChange}
                  canDelete={canDelete}
                  canAddAcademy={canAddAcademy}
                  user={user}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() =>
                        navigate("/cereza-organization/employees/profiles")}
                      disabled={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")}
                      loading={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {!isEqual(formik?.values, formik?.initialValues) && (
                    <>
                      {canDelete && formik?.values?._id && (
                        <UIButton
                          label={t("general:Eliminar")}
                          onClick={() => {
                            Swal.fire({
                              title: t("general:Are"),
                              text: t("general:Youwon"),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: t("general:deleteit"),
                            }).then((result) => {
                              if (result.isConfirmed) {
                                deleteItem(formik);
                                Swal.fire({
                                  title: t("general:Deleted"),
                                  text: t("general:YourFileHasBeenDeleted"),
                                  icon: "success",
                                });
                              }
                            });
                          }}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {canAdd && (
                        <UIButton
                          label={t("general:Nuevo")}
                          onClick={() => formik.setValues(formik.initialValues)}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                    </>
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </UIPaper>
    </>
  );
};
