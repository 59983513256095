import { Grid, Stack, Typography } from "@mui/material";
import { UIDatePicker, UISelect } from "components";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const FormCurrentEmployment = (
  {
    formik,
    locations,
    jobDescriptionOptions,
    supervisorsOptions,
    canChange,
    mapSupervisors,
    jobDescriptionData,
  },
) => {
  const { t } = useTranslation(["employee"]["general"]);

  useEffect(() => {
    if (formik.values.position !== formik.initialValues.position) {
      formik.setFieldValue("supervisor", "");
    } else {
      formik.setFieldValue("supervisor", formik.initialValues.supervisor);
    }

    mapSupervisors(formik.values);
  }, [formik.values.position]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item={true} xs={12} md={5}>
          <UISelect
            formik={formik}
            label={t("employee:CurrentPosition")}
            name={"position"}
            options={jobDescriptionOptions}
            disabled={!canChange || formik.isSubmitting}
          />
        </Grid>
        {formik.values.position && (
          <Grid item={true} xs={12} md={7}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                height: "100%",
              }}
              spacing={2}
            >
              <Typography variant="h6">
                {t("employee:department")}: {jobDescriptionData[formik.values.position]?.department.name}
              </Typography>
            </Stack>
          </Grid>
        )}
      </Grid>
      <Grid container={true} spacing={2} mt={1}>
        <Grid item={true} xs={12} md={3}>
          <UIDatePicker
            formik={formik}
            label={t("employee:Empezóeltrabajo")}
            name={"startDateCurrentPosition"}
            disabled={!canChange || formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelect
            formik={formik}
            label={t("employee:Lugardetrabajo")}
            name={"workLocation"}
            options={locations}
            disabled={!canChange || formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UISelect
            formik={formik}
            label={t("employee:Supervisor")}
            name={"supervisor"}
            options={supervisorsOptions}
            disabled={!canChange || formik.isSubmitting}
          />
        </Grid>
        <Grid item={true} xs={12} md={3}>
          <UIDatePicker
            formik={formik}
            label={t("employee:InicioRelaciónLaboral")}
            name={"dateJoiningCompany"}
            disabled={!canChange || formik.isSubmitting}
          />
        </Grid>
      </Grid>
    </>
  );
};
