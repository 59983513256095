import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Select,
  Box,
  Chip,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const UISelectCheckmarks = ({
  options,
  label = "",
  formik,
  name = "",
  index = null,
  nameFileArray = "",
  fieldRender = "title",
  maxHeightValue = "auto",
  ...props
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    nameFileArray === ""
      ? formik.setFieldValue(name, value)
      : formik.setFieldValue(`${nameFileArray}.${index}.${name}`, value);
  };

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={
            nameFileArray === ""
              ? formik.values[name]
              : formik.values[nameFileArray][index][name]
          }
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                maxHeight: maxHeightValue,
              }}
            >
              {options
                .filter((option) => selected.includes(option._id))
                .map((option) => (
                  <Chip key={option._id} label={option[fieldRender]} />
                ))}
            </Box>
          )}
          MenuProps={MenuProps}
          {...props}
        >
          {options.map((obj, index) => (
            <MenuItem key={index} value={obj?._id}>
              <Checkbox
                checked={
                  nameFileArray === ""
                    ? formik.values[name].indexOf(obj?._id) > -1
                    : formik.values[nameFileArray][index][name].indexOf(
                        obj?._id
                      ) > -1
                }
              />
              <ListItemText primary={obj[fieldRender]} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
