import { cerezaClient } from "../adapters";

const ORGANIZATIONS = "/v1/org";
const BENEFITS = "/v1/emps/employee/benefits";
const BENEFITS_PACKAGE = "/v1/emps/employee/benefit/shortcuts";
const TENANT = "/v1/org/settings/tenant";
const GLOBAL = "/v1/glob";

export function getDepartments(param, value = "") {
  if (param === "selectors") {
    return cerezaClient.get(`${ORGANIZATIONS}/department?${param}=true`);
  } else if (param === "id") {
    return cerezaClient.get(`${ORGANIZATIONS}/department?${param}=${value}`);
  } else {
    return cerezaClient.get(`${ORGANIZATIONS}/department`);
  }
}

export function saveDepartment(data) {
  return cerezaClient.post(`${ORGANIZATIONS}/department`, data);
}

export function updateDepartment(departmentId, data) {
  return cerezaClient.put(`${ORGANIZATIONS}/department/${departmentId}`, data);
}

export function deleteDepartment(departmentId) {
  return cerezaClient.delete(`${ORGANIZATIONS}/department/${departmentId}`);
}

export function updateFirstDepartment(departmentId, data) {
  return cerezaClient.patch(
    `${ORGANIZATIONS}/first/department/${departmentId}`,
    data
  );
}

export function getLocations(param, value = "") {
  if (param === "selectors") {
    return cerezaClient.get(`${ORGANIZATIONS}/location?${param}=true`);
  } else if (param === "id") {
    return cerezaClient.get(`${ORGANIZATIONS}/location?${param}=${value}`);
  } else {
    return cerezaClient.get(`${ORGANIZATIONS}/location`);
  }
}

export function postLocation(data) {
  return cerezaClient.post(`${ORGANIZATIONS}/location`, data);
}

export function putLocation(data, id) {
  return cerezaClient.put(`${ORGANIZATIONS}/location/${id}`, data);
}

export function getAssets() {
  return cerezaClient.get(`${ORGANIZATIONS}/assets`);
}

export function saveAssets(data) {
  return cerezaClient.post(`${ORGANIZATIONS}/assets`, data);
}

export function updateAssets(data, id) {
  return cerezaClient.put(`${ORGANIZATIONS}/assets/${id}`, data);
}

export function getAssetTypes() {
  return cerezaClient.get(`${ORGANIZATIONS}/assets/types`);
}

export function postBenefits(payload) {
  return cerezaClient.post(`${BENEFITS}`, payload);
}

export function putBenefits(id, payload) {
  return cerezaClient.put(`${BENEFITS}/${id}`, payload);
}

export function getBenefitPackage() {
  return cerezaClient.get(`${BENEFITS_PACKAGE}`);
}

export function postBenefitPackage(payload) {
  return cerezaClient.post(`${BENEFITS_PACKAGE}`, payload);
}

export function putBenefitPackage(id, payload) {
  return cerezaClient.put(`${BENEFITS_PACKAGE}/${id}`, payload);
}

export function getTenantSettings() {
  return cerezaClient.get(`${TENANT}`);
}

export function patchTenantSettings(id, payload) {
  return cerezaClient.patch(`${TENANT}/${id}`, payload);
}

export function putSkills(id, payload) {
  return cerezaClient.put(`${GLOBAL}/skill/${id}`, payload);
}

export function postSkills(payload) {
  return cerezaClient.post(`${GLOBAL}/skill`, payload);
}

export function getJobPositions(id) {
  if (id) {
    return cerezaClient.get(`${ORGANIZATIONS}/job-position/${id}`);
  } else {
    return cerezaClient.get(`${ORGANIZATIONS}/job-position`);
  }
}

export function getJobPositionsRequest() {
  return cerezaClient.get(
    `${ORGANIZATIONS}/job-position?status=PENDING&&status=REVIEW_REQUIRED`
  );
}

export function postJobPositions(data, isWithFlow=true) {
    if (isWithFlow) {
        return cerezaClient.post(`${ORGANIZATIONS}/job-position`, data);
    } else {
        return cerezaClient.post(`${ORGANIZATIONS}/job-position?auto_approve=OK`, data);
    }
}

export function putJobPositions(data) {
  return cerezaClient.put(`${ORGANIZATIONS}/job-position/${data._id}`, data);
}

export function deleteJobPositions(data) {
  return cerezaClient.delete(`${ORGANIZATIONS}/job-position/${data._id}`);
}
