import { Add as AddIcon } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalFacialCapture } from "./ModalFaceCapture";
import { useSelector } from "react-redux";
import { profileSelect } from "@redux/slices/profile";
import { getFacialRecognition } from "api/employee";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { getFiles } from "api";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const PhotoPreview = (
  { photo, setPhoto, setOpen, canChange },
) => {
  const { tenant } = useSelector(tenantSettingsSelect);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    const fetchSource = async () => {
      if (photo.thumbnail) {
        setPreview(photo.thumbnail);
      } else {
        try {
          const { data } = await getFiles(tenant._id, photo.filePath);

          if (/data:image/.test(data.content)) {
            setPreview(data.content);
          }
        } catch (err) {
          console.error("Error getting file: ", err);
        }
      }
    };

    fetchSource();
  }, []);

  const media = (
    <CardMedia
      component="img"
      image={preview}
      height={200}
    >
    </CardMedia>
  );

  console.log(media);

  return canChange
    ? (
      <CardActionArea
        onClick={() => {
          setPhoto(photo);
          setOpen(true);
        }}
      >
        {media}
      </CardActionArea>
    )
    : media;
};

export const FacialRecognition = (
  { canAdd, canChange, employeeSelected },
) => {
  const { t } = useTranslation(["employee"]["general"]);

  const [open, setOpen] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [appearance, setAppearance] = useState("appearance_1");
  const appearances_total = [1, 2, 3, 4];
  const [appearances, setAppearances] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getFacialRecognition(employeeSelected._id);
        console.log(data);
        setAppearances(data.reduce((previous, current) => {
          if (!previous.hasOwnProperty(current.name)) {
            previous[current.name] = [];
          }

          previous[current.name].push(current);

          return previous;
        }, {}));
      } catch (err) {
        console.error("Error getting data:", err);
      }
    };

    fetchData();
  }, [updateData]);

  return (
    <>
      <Typography variant="h5" mt={2} mb={2}>
        {t("employee:FacialRecognition")}
      </Typography>
      {appearances
        ? (
          <>
            <TabContext value={appearance}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(e, newValue) => setAppearance(newValue)}
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  {appearances_total.map((item) => (
                    <Tab
                      key={`appearance_${item}`}
                      label={`${t("employee:Appearance")} ${item}`}
                      value={`appearance_${item}`}
                    />
                  ))}
                </TabList>
              </Box>
              {appearances_total.map((item) => (
                <TabPanel
                  key={`appearance_${item}`}
                  value={`appearance_${item}`}
                >
                  {(appearances[`appearance_${item}`] === undefined ||
                    appearances[`appearance_${item}`].length < 15) &&
                    (
                      <Alert severity="info">
                        {t("employee:OptimalFacialRecognition")}
                      </Alert>
                    )}
                  <Grid container={true} gap={2} mt={2}>
                    {canAdd &&
                      (
                        <Grid
                          item={true}
                          md={2}
                          xs={12}
                          component={Button}
                          variant="outlined"
                          fullWidth={true}
                          sx={{
                            height: 200,
                          }}
                          onClick={() => {
                            setPhoto(null);
                            setOpen(true);
                          }}
                        >
                          <AddIcon sx={{ fontSize: 70 }} />
                        </Grid>
                      )}
                    {appearances[`appearance_${item}`] !== undefined &&
                      appearances[`appearance_${item}`].map(
                        (p) => {
                          return (
                            <Grid
                              item={true}
                              md={2}
                              xs={12}
                              key={p._id}
                              component={Card}
                            >
                              <PhotoPreview
                                key={p._id}
                                photo={p}
                                setOpen={setOpen}
                                setPhoto={setPhoto}
                                canChange={canChange}
                              />
                            </Grid>
                          );
                        },
                      )}
                  </Grid>
                </TabPanel>
              ))}
            </TabContext>
            <ModalFacialCapture
              open={open}
              setOpen={setOpen}
              photo={photo}
              setUpdateData={setUpdateData}
              appearance={appearance}
            />
          </>
        )
        : ""}
    </>
  );
};
