import { FastField, useField } from "formik";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const UIDatePickerFast = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <FastField {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => (
        <>
          <DatePicker
            sx={{
              width: "100%",
            }}
            format="YYYY/MM/DD"
            onChange={(date) => {
              setFieldValue(name, date.format("YYYY-MM-DD"));
            }}
            {...field}
            {...rest}
            value={field.value ? dayjs.utc(field.value) : dayjs.utc()}
          />
          {touched[field.name] &&
            errors[field.name] && <div>{errors[field.name]}</div>}
        </>
      )}
    </FastField>
  );
};
