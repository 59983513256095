import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import {
  Alert,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  MenuList,
  Stack,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ModalBaseSalary } from "./ModalBaseSalary";
import { profileSelect } from "@redux/slices/profile";
import { generalSelect, setCurrencies } from "@redux/slices/general";
import {
  getBenefits,
  getBenefitsPackages,
  getCurrencies,
  getEmployeeBenefits,
  updateEmployeeBenefits,
} from "api";
import {
  ProfileTabs,
  Spinner,
  TitleBox,
  Toast,
  UIButton,
  UIPaper,
  UISelectCheckmarks,
} from "components";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { uiSelect } from "@redux/slices/ui";
import { pink } from "@mui/material/colors";
import { useAccess } from "hooks";
import { useNavigate } from "react-router-dom";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { pathEmployee } from "helpers";

export const Compensation = ({ application = "organization", user = null }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]);

  const dispatcher = useDispatch();

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const { currencies } = useSelector(generalSelect);
  const { mainColor } = useSelector(parameterizationSelect);
  const { preferences } = useSelector(uiSelect);

  const [benefits, setBenefits] = useState([]);
  const [benefitPackages, setBenefitPackages] = useState([]);
  const [open, setOpen] = useState(false);
  const [employeeBenefits, setEmployeeBenefits] = useState({});
  const [alert, setAlert] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchorEl = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { HasPermissions } = useAccess();
  const { canChange } = HasPermissions(
    pathEmployee(application, "/profile/compensation"),
  );
  const { canAccess: canAccessSalary, canChange: canChangeSalary } =
    HasPermissions(pathEmployee(application, "/profile/compensation-base"));

  useEffect(() => {
    const fetchBenefits = async () => {
      try {
        const { data } = await getBenefits();
        setBenefits(data);
      } catch (err) {
        console.error("Error fetching benefits:", err);
      }
    };

    const fetchBenefitsPackages = async () => {
      try {
        const { data } = await getBenefitsPackages();
        setBenefitPackages(data.filter((obj) => obj.isActive === true));
      } catch (err) {
        console.error("Error fetching benefits:", err);
      }
    };

    const fetchEmployeeBenefits = async () => {
      try {
        const { data } = await getEmployeeBenefits(employeeSelected._id);
        if (Object.keys(data).length === 0) {
          setEmployeeBenefits({
            employee: employeeSelected._id,
            benefits: [],
          });
        } else {
          setEmployeeBenefits(data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    const fetchCurrencies = async () => {
      if (currencies?.length === 0 || !currencies || currencies === undefined) {
        try {
          const { data } = await getCurrencies(true);
          dispatcher(setCurrencies(data));
        } catch (err) {
          console.error("Error getting currencies:", err);
        }
      }
    };

    fetchBenefits();
    fetchBenefitsPackages();
    fetchCurrencies();
    fetchEmployeeBenefits();
  }, []);

  const handleSubmit = async (values, { setValues }) => {
    const { createdAt, updatedAt, ...rest } = values;
    try {
      if (values?._id) {
        const { data } = await updateEmployeeBenefits(rest, values._id);
        setEmployeeBenefits(data);
      }
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: err?.response?.data?.message?.errors,
      });
      console.error("Error submitting form:", err);
    }
  };

  const moneyFormat = (amount, id) => {
    const item = currencies.find((currency) => currency.value === id);
    return `${item?.prefix} ${amount} ${item?.label}`;
  };

  const makeBenefitDetails = (id) => {
    const item = benefits.find((benefit) => benefit?._id === id);

    return `${item?.title} paid every ${item?.periodicity} days for an amount of ${
      moneyFormat(
        item?.amount,
        item?.currency,
      )
    }.`;
  };

  setTimeout(() => {
    setAlert(true);
  }, 4000);

  return (
    <>
      <ProfileTabs tab_initial="compensation" application={application} />
      <br />
      <UIPaper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
          />
          {Object.keys(employeeBenefits).length > 0 &&
              Object.keys(benefits).length > 0 &&
              Object.keys(currencies).length > 0
            ? (
              <>
                <Formik
                  initialValues={employeeBenefits}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form id="d" autoComplete="off">
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item={true} xs={12} md={8}>
                          <Typography variant="h5">
                            {t("employee:CompensacionesyBeneficios")}
                          </Typography>
                        </Grid>
                        <Grid item={true} xs={12} md={4}>
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            {canAccessSalary && (
                              <Grid item={true} xs={12} md={6}>
                                <UIButton
                                  sx={{ marginRight: "8px", width: "100%" }}
                                  onClick={() =>
                                    setOpen(true)}
                                >
                                  {t("employee:SalarioBase")}
                                </UIButton>
                              </Grid>
                            )}
                            {Object.keys(benefitPackages).length > 0 && (
                              <Grid item={true} xs={12} md={6}>
                                <Button
                                  id="basic-button"
                                  endIcon={<KeyboardArrowDownIcon />}
                                  aria-controls={openAnchorEl
                                    ? "basic-menu"
                                    : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openAnchorEl
                                    ? "true"
                                    : undefined}
                                  onClick={handleClick}
                                  variant="contained"
                                  sx={{
                                    width: "100%",
                                    backgroundColor: mainColor,
                                    "&:hover": {
                                      backgroundColor: pink["A700"],
                                    },
                                  }}
                                >
                                  {t("employee:Atajos")}
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={openAnchorEl}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                  transformOrigin={{
                                    horizontal: "right",
                                    vertical: "top",
                                  }}
                                  anchorOrigin={{
                                    horizontal: "right",
                                    vertical: "bottom",
                                  }}
                                  sx={{
                                    mt: 1,
                                  }}
                                >
                                  <MenuList>
                                    {benefitPackages !== undefined &&
                                      benefitPackages?.map((obj, index) => (
                                        <MenuItem
                                          key={index}
                                          onClick={() => {
                                            handleClose();
                                            const uniqueBenefits = Array?.from(
                                              new Set([
                                                ...formik?.values?.benefits,
                                                ...obj?.benefits,
                                              ]),
                                            );
                                            formik.setFieldValue(
                                              "benefits",
                                              uniqueBenefits,
                                            );
                                          }}
                                        >
                                          {obj?.title}
                                        </MenuItem>
                                      ))}
                                  </MenuList>
                                </Menu>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <br />
                      <Grid container spacing={2}>
                        <Grid item={true} xs={12}>
                          <UISelectCheckmarks
                            options={benefits.filter((item) => {
                              return (
                                (item.isActive && item.isActive === true) ||
                                formik.values?.benefits?.includes(item._id)
                              );
                            })}
                            formik={formik}
                            label={"benefits"}
                            name={"benefits"}
                            disabled={!canChange || formik.isSubmitting}
                          />
                        </Grid>
                      </Grid>
                      <br />
                      <Stack style={{ width: "100%" }} spacing={2}>
                        {formik?.values?.benefits.map((item, index) => (
                          <Alert severity="info" key={index}>
                            {makeBenefitDetails(item)}
                          </Alert>
                        ))}
                      </Stack>
                      <Stack
                        mt={2}
                        direction={{ xs: "column", sm: "row" }}
                        spacing={{ xs: 1, sm: 1, md: 1 }}
                      >
                        {application === "organizacion" && (
                          <UIButton
                            variant="contained"
                            startIcon={<ChevronLeftIcon />}
                            onClick={() =>
                              navigate(
                                "/cereza-organization/employees/profiles",
                              )}
                            disabled={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                        {canChange && (
                          <UIButton
                            type="submit"
                            label={formik?.values?._id
                              ? t("general:Actualizar")
                              : t("general:Guardar")}
                            loading={formik.isSubmitting}
                            sx={{}}
                          />
                        )}
                      </Stack>
                    </Form>
                  )}
                </Formik>
              </>
            )
            : alert === false
            ? <Spinner />
            : (
              <Box
                sx={{
                  display: "grid",
                  placeItems: "center",
                  backgroundColor: preferences?.darkMode
                    ? "rgb(22, 11, 11)"
                    : "rgb(253, 237, 237)",
                }}
              >
                <Alert severity="error">
                  {t("employee:alertNotFoundcompensation")}
                </Alert>
              </Box>
            )}
        </Box>
      </UIPaper>
      <ModalBaseSalary
        open={open}
        setOpen={setOpen}
        canChange={canChangeSalary}
      />
    </>
  );
};
