import { RecordHistory as OrgRecordHistory } from "organizationModules/Employees/Profiles/RecordHistory";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const RecordHistory = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgRecordHistory application="employee" user={{ employeeSelected: user }} />
  );
};
