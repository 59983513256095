import { Typography } from "@mui/material";
import { FastField, useField } from "formik";
import JoditEditor from "jodit-react";
import { useRef } from "react";

export const UIJoditEditor = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);
  const editorRef = useRef(null);

  return (
    <FastField {...field} {...rest}>
      {({ field, form: { touched, errors, setFieldValue } }) => (
        <>
          <Typography variant="caption" color={"primary"}>
            {props.label}
          </Typography>
          <JoditEditor
            ref={editorRef}
            value={field.value}
            config={{
              addNewLine: false,
              disabled: props.disabled,
              cleanHTML: {
                denyTags: {
                  script: false,
                },
              },
            }}
            onBlur={(newContent) => setFieldValue(field.name, newContent)}
          />
          {touched[field.name] &&
            errors[field.name] && <div>{errors[field.name]}</div>}
        </>
      )}
    </FastField>
  );
};
