import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProfileTabs, Toast, UIButton, UIPaper } from "components";
import { FormPersonalInformation } from "./FormPersonalInformation";
import { Box, Stack, Switch, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { profileSelect, setEmployeeSelected } from "@redux/slices/profile";
import { enableOrDisableUser, updateEmployee } from "api";
import { managerErrors } from "utils";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useAccess } from "hooks";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathEmployee } from "helpers";

dayjs.extend(utc);

const createInitialValues = (values) => {
  if (values || values !== undefined) {
    const iValues = { ...values };
    delete iValues.isActive;
    delete iValues.createdAt;
    delete iValues.updatedAt;
    return iValues;
  } else {
    return {
      names: "",
      surnames: "",
      email: "",
      gender: "",
      dateOfBirth: dayjs(),
      bloodType: "",
      nationality: "",
      maritalStatus: "",
      hobbies: [],
      socialSecurityNumber: "",
      taxIdentificationNumber: "",
      photo: "",
      number: "",
    };
  }
};

export const PersonalInformation = (
  { application = "organization", user = null },
) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const dispatch = useDispatch();
  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [initialValues, setInitialValues] = useState(
    createInitialValues(employeeSelected),
  );

  const { HasPermissions } = useAccess();
  const { canAdd, canChange } = HasPermissions(
    pathEmployee(application, "/profile/collaborator/personal-information"),
    application,
  );

  useEffect(() => {
    setInitialValues(createInitialValues(initialValues));
  }, [employeeSelected]);

  const handleSubmit = async (values) => {
    try {
      const updatedValues = { ...values };
      const { data } = await updateEmployee(
        updatedValues,
        employeeSelected._id,
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  const handleChange = async () => {
    try {
      const { data } = await enableOrDisableUser(
        employeeSelected._id,
        !employeeSelected.isActive,
      );
      dispatch(setEmployeeSelected(data));
      Toast.fire({
        icon: "success",
        title: !employeeSelected.isActive === true
          ? t("employee:enableEmployee")
          : t("employee:disableEmployee"),
      });
    } catch (err) {
      managerErrors(err?.response?.data?.message);
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="collaborator" application={application} />
      <br />
      <UIPaper>
        <Box p={4}>
          <Typography variant="h5">
            {t("employee:InformaciónPersonal")}
          </Typography>
          <br />
          {application === "organization" && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Switch
                color="secondary"
                checked={employeeSelected.isActive}
                onChange={handleChange}
              />
              <Typography variant="subtitle2" sx={{ margin: "auto 0" }}>
                {t("employee:isActive")}
              </Typography>
              <br />
            </Box>
          )}
          <Formik
            initialValues={initialValues}
            //   validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form id="d" autoComplete="off">
                <FormPersonalInformation
                  formik={formik}
                  canAdd={canAdd}
                  canChange={canChange}
                  employeeNumber={application === "organization" ? true : false}
                />
                <Stack
                  mt={2}
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 1, md: 1 }}
                >
                  {application === "organization" && (
                    <UIButton
                      variant="contained"
                      startIcon={<ChevronLeftIcon />}
                      onClick={() =>
                        navigate("/cereza-organization/employees/profiles")}
                      disabled={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  {((canAdd && !formik?.values?._id) ||
                    (canChange && formik?.values?._id)) && (
                    <UIButton
                      type="submit"
                      label={formik?.values?._id
                        ? t("general:Actualizar")
                        : t("general:Guardar")}
                      loading={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </UIPaper>
    </>
  );
};
