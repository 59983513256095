import * as React from "react";
import { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ConstructionIcon from "@mui/icons-material/Construction";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";
import {
  ACCOUNT,
  HOME,
  LERNING_CENTER,
  MEDICAL,
  PERFORMANCE_CENTER,
  PROFILE,
  RESOURCES,
  SHIFT_ATTENDANCE,
  TOOLS,
} from "../routes";
import { useDispatch } from "react-redux";
import { setOpenDialogSearch } from "../redux/slices/general";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { authUserSelect } from "@redux/slices/authUser";

export const SideNav = () => {
  const { t } = useTranslation(["sidenav"]);
  const navigate = useNavigate();
  const dispacht = useDispatch();
  const [open, setOpen] = useState("");

  const { keycloakProfile, permissions } = useSelector(authUserSelect);

  const handleClick = (mainName) => {
    setOpen(mainName === open ? "" : mainName);
  };

  const navigateGenerator = (module, mainName, icon, mainRout) => {
    if (
      keycloakProfile?.is_superuser ||
      permissions?.paths?.includes(mainRout)
    ) {
      if (module.length > 1) {
        return (
          <>
            <ListItemButton onClick={() => handleClick(mainName)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={mainName} />
              {open === mainName ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <Collapse in={open === mainName} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {module.map((route, index) => {
                  if (
                    keycloakProfile?.is_superuser ||
                    permissions?.paths?.includes(route?.route)
                  ) {
                    return (
                      <ListItemButton
                        key={index}
                        sx={{ pl: 4 }}
                        onClick={() => {
                          navigate(route.route);
                        }}
                      >
                        <ListItemIcon>
                          <ArrowRightRoundedIcon />
                        </ListItemIcon>
                        <ListItemText primary={t(`sidenav:${route.name}`)} />
                      </ListItemButton>
                    );
                  }
                })}
              </List>
            </Collapse>
          </>
        );
      } else if ((module.length = 1)) {
        return (
          <>
            {module.map((route, index) => (
              <ListItemButton
                key={index}
                onClick={() => {
                  navigate(route.route);
                }}
              >
                <ListItemIcon>
                  <SendIcon />
                </ListItemIcon>
                <ListItemText primary={t(`sidenav:${route.name}`)} />
              </ListItemButton>
            ))}
          </>
        );
      }
    }
  };

  return (
    <List
      sx={{ width: "100%", maxWidth: 390, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          Cereza Employee
        </ListSubheader>
      }
    >
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <SearchIcon />
        </ListItemIcon>
        <ListItemText
          primary="Search"
          onClick={() => {
            dispacht(setOpenDialogSearch(true));
          }}
        />
      </ListItemButton>
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary="Add" />
      </ListItemButton>
      <ListItemButton
        sx={{
          display: {
            md: "none",
            xs: "flex",
          },
        }}
      >
        <ListItemIcon>
          <NotificationsNoneIcon />
        </ListItemIcon>
        <ListItemText primary="Notifications" />
      </ListItemButton>
      {navigateGenerator(
        HOME,
        t("sidenav:Inicio"),
        <DashboardIcon />,
        "/Inicio"
      )}
      {/* {navigateGenerator(
        ACCOUNT,
        t("sidenav:Cuenta"),
        <ManageAccountsIcon />,
        "/Cuenta"
      )} */}
      {navigateGenerator(
        PROFILE,
        t("sidenav:Perfil"),
        <AssignmentIndIcon />,
        "/Perfil"
      )}
      {/* {navigateGenerator(
        SHIFT_ATTENDANCE,
        t("sidenav:TurnoDeAsistencia"),
        <PunchClockIcon />,
        "/TurnoDeAsistencia"
      )} */}
      {/* {navigateGenerator(
        LERNING_CENTER,
        t("sidenav:CentroDeAprendizaje"),
        <CastForEducationIcon />,
        "/CentroDeAprendizaje"
      )} */}
      {/* {navigateGenerator(
        PERFORMANCE_CENTER,
        t("sidenav:CentroDeRendimiento"),
        <CastForEducationIcon />,
        "/CentroDeRendimiento"
      )} */}
      {/* {navigateGenerator(
        RESOURCES,
        t("sidenav:Recursos"),
        <ViewModuleIcon />,
        "/Recursos"
      )} */}
      {/* {navigateGenerator(
        MEDICAL,
        t("sidenav:Médico"),
        <LocalHospitalIcon />,
        "/Médico"
      )} */}
      {/* {navigateGenerator(
        TOOLS,
        t("sidenav:Herramientas"),
        <ConstructionIcon />,
        "/Herramientas"
      )} */}
    </List>
  );
};
