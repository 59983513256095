import HttpAdapter from "./httpAdapter";

const API_URL = `${
  process.env.env === "production"
    ? "https://api.cereza.io"
    : "https://api-stage.cereza.dev"
}`;

const cerezaClient = new HttpAdapter(process.env.REACT_APP_API_URL);

export { cerezaClient };
