import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { modalStyle } from "helpers";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import { UINumericInput, UISelect, UIButton } from "components";
import { profileSelect } from "@redux/slices/profile";
import { generalSelect, setCurrencies } from "@redux/slices/general";
import {
  getCurrencies,
  getSalaryBase,
  saveSalaryBase,
  updateSalaryBase,
} from "api";
import { getPrefix } from "utils";

export const ModalBaseSalary = ({ open, setOpen, canChange }) => {
  const { t } = useTranslation(["employee"]["general"]);

  const dispatcher = useDispatch();
  const { currencies } = useSelector(generalSelect);
  const { employeeSelected } = useSelector(profileSelect);
  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    const fetchCurrencies = async () => {
      if (currencies?.length === 0 || !currencies || currencies === undefined) {
        try {
          const { data } = await getCurrencies(true);
          dispatcher(setCurrencies(data));
        } catch (err) {
          console.error("Error getting currencies:", err);
        }
      }
    };

    fetchCurrencies();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getSalaryBase(employeeSelected._id);
        const updateData = { ...data };
        delete updateData.createdAt;
        delete updateData.updatedAt;
        setResponseData(updateData);
      } catch (err) {
        console.error("Error getting salary base:", err);
      }
    };

    fetchData();
  }, [employeeSelected]);

  const handleSubmit = async (values, { resetForm }) => {
    if (values?._id) {
      try {
        const { data } = await updateSalaryBase(values, values._id);
        resetForm();
        setOpen(false);
        setResponseData(values);
      } catch (e) {
        console.log("Error submitting from:", e);
      }
    } else {
      try {
        const { data } = await saveSalaryBase(values);
        resetForm();
        setOpen(false);
        const updateData = { ...data };
        delete updateData.createdAt;
        delete updateData.updatedAt;
        setResponseData(updateData);
      } catch (err) {
        console.error("Error submitting form:", err);
      }
    }
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box style={{ width: "auto" }} sx={modalStyle}>
        <Box style={{ position: "absolute", top: "10px", right: "10px" }}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5">{t("employee:SalarioBase")}</Typography>
        <br />
        <Formik
          initialValues={
            Object.keys(responseData).length !== 0
              ? responseData
              : {
                  employee: employeeSelected._id,
                  salary: "",
                  currency: "",
                }
          }
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form id="d" autoComplete="off">
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >
                <Grid item={true} xs={12}>
                  <UISelect
                    formik={formik}
                    label={t("employee:Divisa")}
                    name={"currency"}
                    options={currencies}
                    disabled={!canChange || formik.isSubmitting}
                  />
                </Grid>
                <Grid item={true} xs={12}>
                  <UINumericInput
                    formik={formik}
                    label={t("employee:Salario")}
                    name={"salary"}
                    prefix={
                      getPrefix(formik?.values?.currency, currencies) ?? "$"
                    }
                    disabled={!canChange || formik.isSubmitting}
                  />
                </Grid>
              </Grid>
              <Box
                mt={2}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <Box display="flex" gap="1em">
                  {canChange && (
                    <UIButton
                      type="submit"
                      label={
                        formik?.values?._id
                          ? t("general:Actualizar")
                          : t("general:Guardar")
                      }
                      loading={formik.isSubmitting}
                      sx={{}}
                    />
                  )}
                  <UIButton
                    onClick={() => {
                      setOpen(false);
                    }}
                    label={t("general:Cancel")}
                    disabled={formik.isSubmitting}
                  />
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

ModalBaseSalary.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
