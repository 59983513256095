import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uiState: undefined,
  msgType: undefined,
  apiActions: undefined,
  reRender: 0,
  currentGeolocation: {
    lat: 5.065960599999999,
    lng: -75.4905522,
  },
  preferences: {
    language: {
      name: "Español",
      abb: "es",
    },
  },
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    setUiState: (state, action) => {
      state.uiState = action.payload;
    },
    message: (state, action) => {
      state.msgType = action.payload;
    },
    setApiActions: (state, action) => {
      state.apiActions = action.payload;
    },
    setCurrentGeolocation: (state, action) => {
      state.currentGeolocation = action.payload;
    },
    setReRender: (state, action) => {
      state.reRender = action.payload;
    },
    setUserPreferences: (state, action) => {
      state.preferences = action.payload;
    },
    setCurrentLang: (state, action) => {
      state.preferences.language = action.payload;
    },
  },
});

export const {
  setUiState,
  setApiActions,
  message,
  setCurrentLang,
  setCurrentGeolocation,
  setReRender,
  setUserPreferences,
} = uiSlice.actions;

export const uiSelect = (state) => state.ui;

export default uiSlice.reducer;
