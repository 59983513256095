import { IdentitiesDocuments as OrgIdentitiesDocuments } from "organizationModules/Employees/Profiles/Collaborator/IdentitiesDocuments";
import { authUserSelect } from "@redux/slices/authUser";
import { useSelector } from "react-redux";

export const IdentitiesDocuments = () => {
  const { user } = useSelector(authUserSelect);

  return (
    <OrgIdentitiesDocuments
      application="employee"
      user={{ employeeSelected: user }}
    />
  );
};
