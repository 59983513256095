import { Card, CardHeader, IconButton } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";

export const ChildTree = ({ o, onCollapse, collapsed }) => {
  let style = {
    display: "inline-block",
    minWidth: "200px",
  };

  if (o?.cardStyle && o.cardStyle) {
    style = { ...style, ...o.cardStyle };
  }
  return (
    <Card
      sx={style}
    >
      <CardHeader
        avatar={o.avatar}
        title={o.title}
        subheader={o.subheader}
        action={onCollapse && o.childs?.length && (
          <IconButton onClick={onCollapse}>
            {!collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
        sx={{
          textAlign: "left",
        }}
      />
    </Card>
  );
};

export const OrganizationalTree = ({ o, parent }) => {
  const [collapsed, setCollapsed] = useState(o.collapsed);
  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    o.collapsed = collapsed;
  });

  const T = parent ? TreeNode : (props) => (
    <Tree
      {...props}
      lineWidth={"2px"}
      lineColor={"#cacfd2"}
      lineStyle={"solid"}
    >
      {props.children}
    </Tree>
  );

  return collapsed
    ? (
      <T
        label={
          <ChildTree o={o} onCollapse={handleCollapse} collapsed={collapsed} />
        }
      />
    )
    : (
      <T
        label={
          <ChildTree o={o} onCollapse={handleCollapse} collapsed={collapsed} />
        }
      >
        {o.childs?.map((c, index) => (
          <OrganizationalTree key={index} o={c} parent={o} />
        ))}
      </T>
    );
};
