import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Box, Drawer, Toolbar, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import { SideNav } from "./SideNav";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import BrandMarca from "../assets/brand/marca.png";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ChatBubbleOutlinedIcon from "@mui/icons-material/ChatBubbleOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Language } from "./Language";
import { FooterCopyright } from "./Footer";
import Popover from "@mui/material/Popover";
import MenuList from "@mui/material/MenuList";
import LockResetIcon from "@mui/icons-material/LockReset";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import { setOpenDialogSearch } from "../redux/slices/general";
import { DialogSearch } from "../app/modules/SearchAdvanced/DialogSearch";
import { useTranslation } from "react-i18next";
import { SignOut } from "@keycloak/logout";
import { authUserSelect } from "@redux/slices/authUser";
import Alert from "@mui/material/Alert";
import { setCurrentPlan, tenantSettingsSelect } from "@redux/slices/tenant";
import { calcularDiasPasados } from "helpers";
import { MainBox } from "components";
import { getPlan, postChangeEmail } from "api";
import avatar from "../assets/illustrations/avatar.jpeg";
import { setEmail, setTimer, publicSelect } from "@redux/slices/public";
import { ChangePassword } from "./ChangePassword";
import Swal from "sweetalert2";
import { setUser } from "@redux/slices/authUser";
import { getFiles } from "api";
import {
  parameterizationSelect,
  setLogo,
} from "@redux/slices/parameterization";

const ColorBaseAppBar = "#606060";

const ButtonActionAppBar = styled(Button)(({ theme }) => ({
  color: ColorBaseAppBar,
  variant: "outlined",
  padding: "5px 5px",
  borderRadius: "15px",
  border: "2px solid",
  minWidth: "40px",
  borderColor: ColorBaseAppBar,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const DrawerHeaderLeft = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

function isWithinFiveMinutes(dateTime) {
  const inputTime = new Date(dateTime);

  const currentTime = new Date();

  const differenceInMilliseconds = currentTime - inputTime;

  const differenceInMinutes = differenceInMilliseconds / (1000 * 60);

  return differenceInMinutes <= 5;
}

export default function TemplateGridUI({ children }) {
  const dispatcher = useDispatch();

  const { user, keycloakProfile, permissions } = useSelector(authUserSelect);
  const { tenant, currentPlan } = useSelector(tenantSettingsSelect);
  const { timer } = useSelector(publicSelect);
  const { logo } = useSelector(parameterizationSelect);

  const [feedBackUserFetch, setFeedBackUserFetch] = React.useState({
    spinner: true,
    msg: null,
  });

  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { t } = useTranslation(["sidenav"]);
  const theme = useTheme();
  const openAvatar = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [stateDrawer, setStateDrawer] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setStateDrawer({ ...stateDrawer, [anchor]: open });
  };

  React.useEffect(() => {
    const fetchPlan = async () => {
      try {
        const { data } = await getPlan(tenant.currentPlan);
        dispatcher(setCurrentPlan(data));
      } catch (error) {
        console.log(error);
      }
    };

    if (tenant?.currentPlan) {
      fetchPlan();
    }
  }, []);

  React.useEffect(() => {
    const fetchSource = async () => {
      try {
        const { data } = await getFiles(tenant?._id, tenant?.companyLogo);
        dispatcher(setLogo(data.content));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (logo === null && tenant?._id && tenant?.companyLogo) {
      fetchSource();
    }

    const fetchThumbnail = async () => {
      try {
        const { data } = await getFiles(tenant?._id, user?.photo);
        dispatcher(setUser({ ...user, thumbnail: data.content }));
      } catch (err) {
        console.error("Error getting photo: ", err);
      }
    };
    if (!user?.thumbnail && user?.photo && tenant?._id) {
      fetchThumbnail();
    }
  }, []);

  function evaluarNumero(numero) {
    if (numero > 15) {
      return "0";
    } else {
      const faltantes = 15 - numero;
      return faltantes;
    }
  }

  const handleUpdatePassword = async (values) => {
    try {
      setOpenChangePassword(true);

      if (timer === null || !isWithinFiveMinutes(timer)) {
        await postChangeEmail({ email: user?.email });
        await dispatcher(setTimer(new Date()));
      }

      await dispatcher(setEmail(user?.email));
      setFeedBackUserFetch({
        spinner: false,
        msg: null,
      });
    } catch (error) {
      setFeedBackUserFetch({
        spinner: false,
        msg: "Error al intentar esta acción, por favor intenta nuevamente",
      });
      console.log(error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>
        {currentPlan?.name === "Free" && (
          <Alert severity="warning">
            {`${t("Versióndemoenplanprofesional,quedan")} ${evaluarNumero(
              calcularDiasPasados(currentPlan?.createdAt)
            )} ${t("día(s)deprueba,contrataalgunodenuestrosplanes")}`}
          </Alert>
        )}

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",
            alignItems: "center",
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: ColorBaseAppBar,
                mr: 2,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo !== null ? logo : BrandMarca} height="50px" />
          </Toolbar>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              justifyContent: "end",
              gap: "1rem",
            }}
          >
            <Stack direction="row" spacing={2}>
              <ButtonActionAppBar
                sx={{
                  pl: 2,
                  pr: 2,
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
                onClick={() => dispatcher(setOpenDialogSearch(true))}
              >
                <SearchIcon />
              </ButtonActionAppBar>
              <ButtonActionAppBar
                sx={{
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
              >
                <AddIcon />
              </ButtonActionAppBar>
              <ButtonActionAppBar
                sx={{
                  display: {
                    md: "inline-flex",
                    xs: "none",
                  },
                }}
                onClick={() => console.log("Notificaciones")}
              >
                <NotificationsNoneIcon />
              </ButtonActionAppBar>
              <ButtonActionAppBar onClick={toggleDrawer("right", true)}>
                <QuestionMarkIcon />
              </ButtonActionAppBar>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                onClick={handleClick}
                aria-controls={openAvatar ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openAvatar ? "true" : undefined}
              >
                <Avatar src={user?.thumbnail ?? avatar} />
              </StyledBadge>
            </Stack>
            <Popover
              open={openAvatar}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleClose}
              sx={{
                mt: 2,
              }}
            >
              <Stack
                spacing={2}
                sx={{
                  margin: "15px",
                }}
              >
                <Stack justifyContent="center" alignItems="center">
                  <Avatar
                    alt="Remy Sharp"
                    src={user?.thumbnail ?? avatar}
                    sx={{ width: 124, height: 124 }}
                  />
                  <Box mt={2} />
                  <Typography>
                    {user?.names} {user?.surnames}
                  </Typography>
                  <Typography>{user?.email}</Typography>
                </Stack>
                <Divider />
                <MenuList sx={{ width: 320, maxWidth: "100%" }}>
                  <MenuItem
                    onClick={() => {
                      Swal.fire({
                        title: t("sidenav:Are"),
                        text: t("sidenav:Youwon"),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t("sidenav:Continuar"),
                      }).then((result) => {
                        if (result.isConfirmed) {
                          handleUpdatePassword();
                        }
                      });
                    }}
                  >
                    <ListItemIcon>
                      <LockResetIcon />
                    </ListItemIcon>
                    <ListItemText>{t("CambiarContraseña")}</ListItemText>
                  </MenuItem>
                  {(keycloakProfile?.is_superuser ||
                    permissions?.apps?.organization === true) && (
                    <MenuItem
                      onClick={() => {
                        window.location.href =
                          "/cereza-organization/home/indicators";
                      }}
                    >
                      <ListItemIcon>
                        <ManageAccountsIcon />
                      </ListItemIcon>
                      <ListItemText>{t("CerezaOrganización")}</ListItemText>
                    </MenuItem>
                  )}
                  <Language />
                  <Divider />
                  <MenuItem
                    onClick={() => {
                      SignOut();
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>{t("CerrarSesion")}</ListItemText>
                  </MenuItem>
                </MenuList>
              </Stack>
            </Popover>
          </Box>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SideNav />
      </Drawer>
      <Drawer
        anchor="right"
        open={stateDrawer["right"]}
        onClose={toggleDrawer("right", false)}
        sx={{
          "&.MuiDrawer-root .MuiDrawer-paper": {
            maxWidth: 340,
            minWidth: 340,
          },
        }}
      >
        <DrawerHeaderLeft>
          <Typography variant="h6">Descripción de ayuda</Typography>
        </DrawerHeaderLeft>
        <Divider />

        <Stack
          spacing={2}
          sx={{
            margin: "15px",
          }}
        >
          <Card variant="outlined">
            <CardContent>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                dignissim leo ac interdum feugiat. Interdum et malesuada fames
                ac ante ipsum primis in faucibus
              </Typography>
            </CardContent>
            <CardActions></CardActions>
          </Card>

          <Button
            size="large"
            variant="outlined"
            startIcon={<ChatBubbleOutlinedIcon />}
            sx={{
              justifyContent: "flex-start",
            }}
          >
            Soporte por chat
          </Button>
          <Button
            size="large"
            variant="outlined"
            startIcon={<MailOutlineIcon />}
            sx={{
              justifyContent: "flex-start",
            }}
          >
            Crear ticket
          </Button>
        </Stack>
      </Drawer>
      <Main open={open}>
        {currentPlan?.name === "Free" && <DrawerHeader />}
        <DrawerHeader />
        <MainBox open={open}>{children}</MainBox>
        <FooterCopyright />
        <DialogSearch />
      </Main>
      <ChangePassword
        open={openChangePassword}
        setOpen={setOpenChangePassword}
        feedBackUserFetch={feedBackUserFetch}
      />
    </Box>
  );
}
