import { useEffect, useState } from "react";
import { getDepartments, getJobPositions, selectingEmployeeList } from "api";
import _ from "lodash";
import { OrganizationalTree } from "./OrganizationalTree";
import { tenantSettingsSelect } from "@redux/slices/tenant";
import { parameterizationSelect } from "@redux/slices/parameterization";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { PersonRemove as PersonRemoveIcon } from "@mui/icons-material";
import { setEmployeeSelected } from "@redux/slices/profile";
import { getEmployee } from "api";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccess } from "hooks";
import { pathEmployee } from "helpers";

function generateNodesTree(employees, setOpen) {
  return employees.map((employee) => {
    let node = {
      title: employee.fullName,
      subheader: employee?.position,
      avatar: employee.thumbnail,
      cardStyle: null,
    };

    const department = employee.department;

    if (department && !department.isInternal) {
      node.cardStyle = { border: "2px dashed #cacfd2" };
    }

    if (department) {
      node.subheader = (
        <>
          <Typography variant="subtitle2" component={"div"}>
            {employee?.position}
          </Typography>
          <Typography variant="caption" color="primary">
            {department.name}
          </Typography>
        </>
      );
    }

    if (employee.supervises && employee.supervises.length) {
      node.childs = generateNodesTree(employee.supervises, setOpen);
    }

    return node;
  });
}

export const Chart = ({ user = null, application = "organization" }) => {
  const { t } = useTranslation(["organization"]["general"]);
  const { tenant } = useSelector(tenantSettingsSelect);
  const { logo } = useSelector(parameterizationSelect);
  const [tree, setTree] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { HasPermissions } = useAccess();
  const { canAccess: canAccessEmployee } = HasPermissions(
    pathEmployee(application, "/profile/collaborator/personal-information"),
    application,
  );

  const actionEmployeeClick = async ({ employee }) => {
    try {
      const { data } = await getEmployee("id", employee._id);
      dispatch(setEmployeeSelected(data));
      navigate(
        "/cereza-organization/employees/profile/collaborator/personal-information",
      );
    } catch (err) {
      console.log("Error gettin employee: ", err);
    }
  };

  const createTree = (
    { departments, employees, jobDescriptions, supervisor, showVacancies },
  ) => {
    let nodes = [];
    let total = employees.length;

    for (var i = 0; i < total; i++) {
      let employee = employees[i];

      if (employee.supervisor === supervisor) {
        employee["supervises"] = _.orderBy(
          createTree({
            departments,
            employees,
            jobDescriptions,
            supervisor: employee.fullName,
            showVacancies,
          }),
          ["positionId"],
          ["desc"],
        );

        if (canAccessEmployee) {
          employee.fullName = (
            <Button
              size="small"
              onClick={() => {
                actionEmployeeClick({ employee });
              }}
            >
              {employee.fullName}
            </Button>
          );
        }
        employee.thumbnail = employee.thumbnail
          ? <Avatar src={employee.thumbnail}></Avatar>
          : <Avatar></Avatar>;
        employee.department = jobDescriptions[employee.positionId]
          ? jobDescriptions[employee.positionId].department
          : null;

        if (!employee.isActive) {
          employee.position = (
            <Tooltip title={t("organization:EmployeeInactive")}>
              <Chip
                color="error"
                label={employee.position}
                icon={<PersonRemoveIcon />}
                size="small"
              />
            </Tooltip>
          );
        }
        const jobDescription = jobDescriptions[employee.positionId];
        const supervises = jobDescription
          ? _.filter(
            jobDescriptions,
            (o) => o.reportAt === jobDescription._id,
          )
          : null;

        if (showVacancies && supervises && supervises.length) {
          let quantity = _.filter(
            supervises,
            (
              s,
            ) => (s.occupyIn._id === employee._id && s.quantity > 0),
          );
          quantity = _.map(quantity, (s) => {
            return {
              fullName: (
                <Typography variant="button" color="secondary" component="div">
                  {t("employee:vacancies")}
                </Typography>
              ),
              position: s.title,
              thumbnail: (
                <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
                  {s.quantity}
                </Avatar>
              ),
              cardStyle: null,
            };
          });

          if (quantity.length) {
            employee.supervises = _.concat(
              employee.supervises,
              quantity,
            );
          }
        }

        if (employee.isActive || employee.supervises.length) {
          nodes.push(employee);
        }
      }
    }

    return nodes;
  };

  useEffect(() => {
    const getDataBase = async () => {
      let { data: departments } = await getDepartments(null);
      let { data: employees } = await selectingEmployeeList();
      let { data: jobDescriptions } = await getJobPositions(null);

      jobDescriptions = jobDescriptions.reduce((acc, j) => {
        let employesPosition = _.filter(
          employees,
          (o) => (o.positionId === j._id && o.isActive),
        );

        j.department = _.find(departments, (o) => o._id === j.department);
        let reportAt = _.filter(employees, (o) => o.positionId === j.reportAt);
        j.occupiedPositions = employesPosition.length;
        j.available = j.occupiedPositions < j.quantity;
        j.quantity = j.quantity - j.occupiedPositions;
        j.occupyIn = reportAt.length ? reportAt[0] : [];

        acc[j._id] = j;

        return acc;
      }, {});

      if (user) {
        let employee = _.find(
          employees,
          (o) => o._id === user.employeeSelected._id,
        );
        const supervises = _.filter(
          employees,
          (o) => o.supervisor === employee.fullName,
        );

        if (supervises && supervises.length) {
          employee.supervisor = "";
          employees = [employee, ...supervises];
        } else {
          let supervisor = _.find(
            employees,
            (o) => o.fullName === employee.supervisor,
          );

          if (supervisor) {
            const supervises = _.filter(
              employees,
              (o) => o.supervisor === supervisor.fullName,
            );

            supervisor.supervisor = "";
            employees = [supervisor, ...supervises];
          } else {
            const supervises = _.filter(
              employees,
              (o) => o.supervisor === "",
            );
            employees = [...supervises];
          }
        }
      }

      let t = {
        title: tenant.legalName,
        subheader: "",
        avatar: <Avatar src={logo} variant="square"></Avatar>,
        cardStyle: null,
        childs: generateNodesTree(
          createTree({
            departments,
            employees,
            jobDescriptions,
            supervisor: "",
            showVacancies: user ? false : true,
          }),
        ),
      };

      setTree(t);
    };

    getDataBase();
  }, []);

  return (
    <Paper>
      <Box p={2} overflow={"auto"}>
        <Typography variant="h5">
          {user ? t("sidenav:WorkTeam") : t("sidenav:Organigrama")}
        </Typography>
      </Box>
      <Box p={2} overflow={"auto"}>
        {tree ? <OrganizationalTree o={tree} /> : (
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Paper>
  );
};
