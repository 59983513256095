import { timeClockClient } from "../adapters";

const TIME_CHECK = "/v1/time-clock";
const PIN = "/v1/employee/reset-pin";
const POLICY = "/v1/policy";
const SCHEDULES = "/v1/schedule";
const SETTINGS_CURRENT_EMPLOYMENT = "/v1/employee/settings";
const HOLIDAYS_WIDGET = "/v1/holidays/widget";

export function deleteTimeCheck(id) {
  return timeClockClient.delete(`${TIME_CHECK}/${id}`);
}
export function getTimeCheck() {
  return timeClockClient.get(TIME_CHECK);
}

export function createTimeCheck(data) {
  return timeClockClient.post(TIME_CHECK, data);
}

export function updateTimeCheck(id, data) {
  return timeClockClient.put(`${TIME_CHECK}/${id}`, data);
}

export function dowloadSSL(id) {
  return timeClockClient.get(`${TIME_CHECK}/dowload/ssl/${id}`, {
    responseType: "blob",
    headers: {
      Accept: "application/x-pem-file",
    },
  });
}

export function updateSSL(id) {
  return timeClockClient.put(`${TIME_CHECK}/renew/ssl/${id}`);
}

export function changePassword(id, data) {
  return timeClockClient.post(`${TIME_CHECK}/change-password/${id}`, data);
}

export function resetPIN(id, data) {
  return timeClockClient.patch(`${PIN}/${id}`, data);
}

export function getSchedules() {
  return timeClockClient.get(`${SCHEDULES}`);
}

export function getEmployeeInTimeClock(id) {
  return timeClockClient.get(`${SETTINGS_CURRENT_EMPLOYMENT}/${id}`);
}

export function updateEmployeeInTimeClock(id, data) {
  return timeClockClient.put(`${SETTINGS_CURRENT_EMPLOYMENT}/${id}`, data);
}

export function getHolidaysWidget() {
  return timeClockClient.get(`${HOLIDAYS_WIDGET}`);
}
