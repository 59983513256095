import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { Details } from "./Details";
import { useTranslation } from "react-i18next";
import { ProfileTabs } from "components/ProfileTabs";
import { UIButton } from "components/UIButton";
import { Box, Stack, Typography } from "@mui/material";
import { Contacts } from "./Contacts";
import { SocialNetworks } from "./SocialNetworks";
import {
  getContactDetails,
  saveContactDetails,
  updateContactDetails,
} from "api";
import { profileSelect } from "@redux/slices/profile";
import { Spinner, TitleBox, Toast } from "components";
import { UIPaper } from "components/UIPaper";
import { useAccess } from "hooks";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathEmployee } from "helpers";

export const ContactDetails = (
  { application = "organization", user = null },
) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["employee"]["general"]);

  const { employeeSelected } = user ? user : useSelector(profileSelect);
  const [initialValues, setInitialValues] = useState(undefined);

  const { HasPermissions } = useAccess();
  const { canAdd, canChange, canDelete } = HasPermissions(
    pathEmployee(application, "/profile/collaborator/contact-details"),
    application,
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getContactDetails(employeeSelected._id);
        if (
          data &&
          typeof data === "object" &&
          Object.keys(data).length === 0
        ) {
          setInitialValues({
            employee: employeeSelected._id,
            address: " ",
            description: "",
            latitude: "",
            longitude: "",
            phone: "",
            cellphone: "",
            personalEmail: "",
            emergencyContacts: [
              {
                names: "",
                surnames: "",
                emergencyPhoneNumber: "",
                relationship: "",
                email: "",
                isEmergencyContact: true,
              },
            ],
            socialNetworks: [],
          });
        }
        const updateData = { ...data };
        delete updateData.createdAt;
        delete updateData.updatedAt;
        setInitialValues(updateData);
      } catch (err) {
        console.error("Error getting contact details:", err);
      }
    };

    fetchData();
  }, [employeeSelected]);

  const handleSubmit = async (values) => {
    try {
      if (values?._id) {
        await updateContactDetails(values, values._id);
      } else {
        await saveContactDetails(values);
      }
      Toast.fire({
        icon: "success",
        title: t("employee:SuccessfullyUpdatedEmployee"),
      });
    } catch (err) {
      Toast.fire({
        icon: "error",
        title: { err },
      });
      console.error("Error submitting form:", err);
    }
  };

  return (
    <>
      <ProfileTabs tab_initial="collaborator" application={application} />
      <br />
      <UIPaper>
        <Box p={4}>
          <TitleBox
            text={`${employeeSelected?.names} ${employeeSelected?.surnames}`}
          />
          <Typography variant="h5">
            {t("employee:Detallesdecontacto")}
          </Typography>
          <br />
          {initialValues === undefined
            ? <Spinner />
            : (
              <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                {(formik) => (
                  <Form id="d" autoComplete="off">
                    <Details
                      formik={formik}
                      canAdd={canAdd}
                      canChange={canChange}
                    />
                    <br />
                    <Contacts
                      formik={formik}
                      nameFileArray={"emergencyContacts"}
                      values={formik.values.emergencyContacts}
                      canAdd={canAdd}
                      canChange={canChange}
                      canDelete={canDelete}
                    />
                    <br />
                    <SocialNetworks
                      formik={formik}
                      nameFileArray={"socialNetworks"}
                      values={formik.values.socialNetworks}
                      canAdd={canAdd}
                      canChange={canChange}
                      canDelete={canDelete}
                    />
                    <Stack
                      mt={2}
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      {application === "organization" && (
                        <UIButton
                          variant="contained"
                          startIcon={<ChevronLeftIcon />}
                          onClick={() =>
                            navigate("/cereza-organization/employees/profiles")}
                          disabled={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                      {canChange && (
                        <UIButton
                          type="submit"
                          label={formik?.values?._id
                            ? t("general:Actualizar")
                            : t("general:Guardar")}
                          loading={formik.isSubmitting}
                          sx={{}}
                        />
                      )}
                    </Stack>
                  </Form>
                )}
              </Formik>
            )}
        </Box>
      </UIPaper>
    </>
  );
};
