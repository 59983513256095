import { Box, Paper, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FlashlightOnIcon from "@mui/icons-material/FlashlightOn";
import FlashlightOffIcon from "@mui/icons-material/FlashlightOff";
import {
  parameterizationSelect,
  setMode,
} from "@redux/slices/parameterization";
import { setUserPreferences, uiSelect } from "@redux/slices/ui";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUserPreferences } from "api";
import { authUserSelect } from "@redux/slices/authUser";

export const DarkMode = () => {
  const { t } = useTranslation(["home"]);
  const dispatch = useDispatch();

  const { preferences } = useSelector(uiSelect);
  const { user } = useSelector(authUserSelect);
  const { mainColor, mode } = useSelector(parameterizationSelect);

  const handleChange = async (event) => {
    dispatch(setMode(event.target.checked ? "dark" : "light"));

    const newPreferences = { ...preferences, darkMode: event.target.checked };
    dispatch(setUserPreferences(newPreferences));

    try {
      await updateUserPreferences(user.userId, newPreferences);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          height: "100% !important",
          backgroundColor: mode === "light" ? "#fff" : `${mainColor}50`,
        }}
      >
        <Box
          p={2}
          sx={{
            height: "100% !important",
            display: "grid",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="subtitle1">
                <b>{mode === "light" ? t("home:On") : t("home:Off")}</b>
              </Typography>
            </Box>
            <Box>
              <Switch
                checked={mode === "light" ? false : true}
                onChange={handleChange}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            {mode === "dark" ? (
              <FlashlightOffIcon sx={{ fontSize: "3rem" }} />
            ) : (
              <FlashlightOnIcon sx={{ fontSize: "3rem", color: "#344767" }} />
            )}
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              fontSize: "1.5rem",
              color: mode === "light" && "#344767",
            }}
          >
            <b>{t("home:DarkMode")}</b>
          </Typography>
        </Box>
      </Paper>
    </>
  );
};
