export const OrganizationNavigation = [
  {
    name: "Home",
    path: "/cereza-organization/home/indicators",
    permissions: [],
  },
  {
    name: "Cuenta",
    path: "/cereza-organization/Cuenta",
    tree: [
      {
        name: "Ajustes",
        path: "/cereza-organization/home/account/configurations",
        permissions: [],
      },
      {
        name: "GestiónDeUsuarios",
        path: "/cereza-organization/home/account/management",
        permissions: [],
      },
      {
        name: "InformaciónDeEmpleado",
        path: "/cereza-organization/home/account/employee",
        permissions: [],
      },
      {
        name: "Cuentas",
        path: "/cereza-organization/home/account/accounts",
        permissions: [],
      },
      {
        name: "Notificaciones",
        path: "/cereza-organization/home/account/notifications",
        permissions: [],
      },
      {
        name: "PlanesYFacturación",
        path: "/cereza-organization/home/account/plans-billing",
        permissions: [],
      },
      {
        name: "HorariosLaborales",
        path: "/cereza-organization/home/account/working-hours",
        permissions: [],
      },
    ],
  },
  {
    name: "Organización",
    path: "/cereza-organization/Organización",
    tree: [
      {
        name: "Organigrama",
        path: "/cereza-organization/organization/departments",
        permissions: [
          "add_departments",
          "change_departments",
          "delete_departments",
          "view_departments",
        ],
      },
      {
        name: "DesarrolloDeCarrera",
        path: "/cereza-organization/organization/career-development",
        permissions: [],
      },
      {
        name: "CompensaciónYBeneficios",
        path: "/cereza-organization/organization/compensation-benefits",
        tree: [
          {
            name: "CompensaciónYBeneficios",
            path: "/cereza-organization/organization/compensation-benefits",
            permissions: [
              "add_benefits",
              "change_benefits",
              "delete_benefits",
              "view_benefits",
            ],
          },
          {
            name: "CompensaciónYBeneficiosPackage",
            path: "/cereza-organization/organization/compensation-benefits-package",
            permissions: [
              "add_benefitShortcuts",
              "change_benefitShortcuts",
              "delete_benefitShortcuts",
              "view_benefitShortcuts",
            ],
          },
        ],
      },
      {
        name: "EncuestaDeClimaLaboral",
        path: "/cereza-organization/organization/workplace-climate-survey",
        permissions: [],
      },
      {
        name: "ReglasDeClimaLaboral",
        path: "/cereza-organization/organization/workplace-climate-rules",
        permissions: [],
      },
      {
        name: "EncuestaDeCompromisoDeEmpleado",
        path: "/cereza-organization/organization/employee-engagement-survey",
        permissions: [],
      },
      {
        name: "ClimaLaboral",
        path: "/cereza-organization/organization/workplace-climate",
        permissions: [],
      },
      {
        name: "Integraciones",
        path: "/cereza-organization/organization/integrations",
        permissions: [],
      },
      {
        name: "Tercero",
        path: "/cereza-organization/organization/third-party",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/organization/organization-configurations",
        tree: [
          {
            name: "Ajustes",
            path: "/cereza-organization/organization/organization-settings",
            permissions: [
              "add_tenants",
              "change_tenants",
              "delete_tenants",
              "view_tenants",
            ],
          },
          {
            name: "Ajustes-UbicationsOrg",
            path: "/cereza-organization/organization/organization-settings/ubications-org",
            permissions: [
              "add_locations",
              "change_locations",
              "delete_locations",
              "view_locations",
            ],
          },
          {
            name: "Ajustes-Catalogos",
            path: "/cereza-organization/organization/organization-settings/catalogs",
            tree: [
              {
                name: "Ajustes-Catalogos-Habilidades",
                path: "/cereza-organization/organization/organization-settings/catalogs",
                permissions: [
                  "add_skills",
                  "change_skills",
                  "delete_skills",
                  "view_skills",
                ],
              },
              {
                name: "Ajustes-Catalogos-Divisas",
                path: "/cereza-organization/organization/organization-settings/catalogs-currencies",
                permissions: [
                  "add_tenantCurrencies",
                  "change_tenantCurrencies",
                  "delete_tenantCurrencies",
                  "view_tenantCurrencies",
                ],
              },
              {
                name: "Ajustes-Catalogos-Idiomas",
                path: "/cereza-organization/organization/organization-settings/catalogs-languages",
                permissions: [
                  "add_tenantLanguages",
                  "change_tenantLanguages",
                  "delete_tenantLanguages",
                  "view_tenantLanguages",
                ],
              },
            ],
          },
          {
            name: "Ajustes-Plan",
            path: "/cereza-organization/organization/organization-settings/plan",
            permissions: [],
          },
          {
            name: "Ajustes-Planes",
            path: "/cereza-organization/Planes",
            permissions: [],
          },
        ],
      },
    ],
  },
  {
    name: "Reclutamiento",
    path: "/cereza-organization/Reclutamiento",
    tree: [
      {
        name: "Candidatos",
        path: "/cereza-organization/recruitment/candidates",
        permissions: [],
      },
      {
        name: "OfertasDeTrabajo",
        path: "/cereza-organization/recruitment/postings",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/recruitment/recruitment-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "Empleados",
    path: "/cereza-organization/Empleados",
    tree: [
      {
        name: "Perfiles",
        path: "/cereza-organization/employees/profiles-menu-option",
        tree: [
          {
            name: "Perfiles",
            path: "/cereza-organization/employees/profiles",
            permissions: [
              "add_employees",
              "change_employees",
              "delete_employees",
              "view_employees",
            ],
          },
          {
            name: "Perfil-InformaciónPersonal",
            path: "/cereza-organization/employees/profile/collaborator/personal-information",
            permissions: [
              "add_employees",
              "change_employees",
              "delete_employees",
              "view_employees",
            ],
          },
          {
            name: "Perfil-DetallesDeContacto",
            path: "/cereza-organization/employees/profile/collaborator/contact-details",
            permissions: [
              "add_contactDetails",
              "change_contactDetails",
              "delete_contactDetails",
              "view_contactDetails",
            ],
          },
          {
            name: "Perfil-DocumentosDeIdentidad",
            path: "/cereza-organization/employees/profile/collaborator/identities-documents",
            permissions: [
              "add_identities",
              "change_identities",
              "delete_identities",
              "view_identities",
            ],
          },
          {
            name: "Perfil-HistorialAcadémico",
            path: "/cereza-organization/employees/profile/competencies/academy-history",
            permissions: [
              "add_academicBackground",
              "change_academicBackground",
              "delete_academicBackground",
              "view_academicBackground",
            ],
          },
          {
            name: "Perfil-Habilidades",
            path: "/cereza-organization/employees/profile/competencies/skills-summary-tab",
            tree: [
              {
                name: "Perfil-Habilidades",
                path: "/cereza-organization/employees/profile/competencies/skills-summary",
                permissions: [
                  "add_employeeSkills",
                  "change_employeeSkills",
                  "delete_employeeSkills",
                  "view_employeeSkills",
                ],
              },
              {
                name: "Perfil-Habilidades-Idiomas",
                path: "/cereza-organization/employees/profile/competencies/skills-summary-languages",
                permissions: [
                  "add_employeeLanguages",
                  "change_employeeLanguages",
                  "delete_employeeLanguages",
                  "view_employeeLanguages",
                ],
              },
            ],
          },
          {
            name: "Perfil-EmpleoActual",
            path: "/cereza-organization/employees/profile/employment-information/current-employment-tab",
            tree: [
              {
                name: "Perfil-EmpleoActual",
                path: "/cereza-organization/employees/profile/employment-information/current-employment",
                permissions: [
                  "change_employmentInfo",
                  "view_employmentInfo",
                ],
              },
              {
                name: "Perfil-EmpleoActual-timeClocks",
                path: "/cereza-organization/employees/profile/employment-information/current-employment-timeClocks",
                permissions: [
                  "change_timeClockEmployees",
                  "view_timeClockEmployees",
                  "view_timeClocks",
                  "view_policies",
                  "view_schedules",
                ],
              },
            ],
          },
          {
            name: "Perfil-HistorialDeEmpleo",
            path: "/cereza-organization/employees/profile/employment-information/employment-summary",
            permissions: [
              "add_employmentHistory",
              "change_employmentHistory",
              "delete_employmentHistory",
              "view_employmentHistory",
            ],
          },
          {
            name: "Perfil-Compensación",
            path: "/cereza-organization/employees/profile/compensation-tab",
            tree: [
              {
                name: "Perfil-Compensación",
                path: "/cereza-organization/employees/profile/compensation",
                permissions: [
                  "change_employeeBenefits",
                  "view_employeeBenefits",
                ],
              },
              {
                name: "Perfil-SalarioBase",
                path: "/cereza-organization/employees/profile/compensation-base",
                permissions: [
                  "add_salaryBase",
                  "change_salaryBase",
                  "delete_salaryBase",
                  "view_salaryBase",
                ],
              },
            ],
          },
          {
            name: "Perfil-Documentos",
            path: "/cereza-organization/employees/profile/documents",
            permissions: [
              "add_files",
              "change_files",
              "view_files",
            ],
          },
          {
            name: "Perfil-Assets",
            path: "/cereza-organization/employees/profile/assets",
            permissions: [
              "add_assets",
              "change_assets",
              "delete_assets",
              "view_assets",
            ],
          },
          {
            name: "Perfil-HistoralDeRegistro",
            path: "/cereza-organization/employees/profile/record-history",
            permissions: [
              "add_recordHistory",
              "change_recordHistory",
              "delete_recordHistory",
              "view_recordHistory",
            ],
          },
        ],
      },
      {
        name: "MétricasYObjetivos",
        path: "/cereza-organization/employees/metrics-objectives",
        permissions: [],
      },
      {
        name: "DiscapacidadesMédicas",
        path: "/cereza-organization/employees/medical-disabilities",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/employees/employees-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "TurnoDeAsistencia",
    path: "/cereza-organization/TurnoDeAsistencia",
    tree: [
      {
        name: "AsistenciaAusencia",
        path: "/cereza-organization/shift-attendance/attendance-absence",
        permissions: [],
      },
      {
        name: "Polices",
        path: "/cereza-organization/shift-attendance/polices",
        tree: [
          {
            name: "Polices",
            path: "/cereza-organization/shift-attendance/polices",
            permissions: [
              "add_policies",
              "change_policies",
              "delete_policies",
              "view_policies",
            ],
          },
          {
            name: "Polices-Policy",
            path: "/cereza-organization/shift-attendance/policy",
            permissions: [
              "add_policies",
              "change_policies",
              "delete_policies",
              "view_policies",
            ],
          },
        ],
      },
      {
        name: "VacacionesDeTiempoLibre",
        path: "/cereza-organization/shift-attendance/time-off-holidays",
        tree: [
          {
            name: "VacacionesDeTiempoLibre",
            path: "/cereza-organization/shift-attendance/time-off-holidays",
            permissions: [
              "add_holidays",
              "change_holidays",
              "delete_holidays",
              "view_holidays",
            ],
          },
          {
            name: "VacacionesDeTiempoLibre-Holidays",
            path: "/cereza-organization/shift-attendance/time-off-holidays/holidays",
            permissions: [
              "add_holidays",
              "change_holidays",
              "delete_holidays",
              "view_holidays",
            ],
          },
        ],
      },
      {
        name: "HorarioDeTurnoDeTrabajoDeCereza",
        path: "/cereza-organization/shift-attendance/work-schedules",
        tree: [
          {
            name: "HorarioDeTurnoDeTrabajoDeCereza",
            path: "/cereza-organization/shift-attendance/work-schedules",
            permissions: [
              "add_schedules",
              "change_schedules",
              "delete_schedules",
              "view_schedules",
            ],
          },
          {
            name: "HorarioDeTurnoDeTrabajoDeCereza-WorkSchedule",
            path: "/cereza-organization/shift-attendance/work-schedule",
            permissions: [
              "add_schedules",
              "change_schedules",
              "delete_schedules",
              "view_schedules",
            ],
          },
        ],
      },
      {
        name: "Seguimiento",
        path: "/cereza-organization/shift-attendance/tracking",
        permissions: [
          "add_checks",
          "change_checks",
          "delete_checks",
          "view_checks",
        ],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/shift-attendance/shift-attendance-settings",
        permissions: [
          "add_timeClocks",
          "change_timeClocks",
          "delete_timeClocks",
          "view_timeClocks",
        ],
      },
    ],
  },
  {
    name: "CentroDeAprendizaje",
    path: "/cereza-organization/CentroDeAprendizaje",
    tree: [
      {
        name: "AcademiaCereza",
        path: "/cereza-organization/learning-center/cereza-academy",
        permissions: [],
      },
      {
        name: "LearningHub",
        path: "/cereza-organization/learning-center/learning-hub",
        permissions: [],
      },
      {
        name: "EvaluaciónDeCompetencias",
        path: "/cereza-organization/learning-center/competency-assessment",
        permissions: [],
      },
      {
        name: "Mercado",
        path: "/cereza-organization/learning-center/marketplace",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/learning-center/marketplace-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "CentroDeRendimiento",
    path: "/cereza-organization/CentroDeRendimiento",
    tree: [
      {
        name: "IndicadoresClave",
        path: "/cereza-organization/performance-center/key-indicators",
        permissions: [],
      },
      {
        name: "OKRs",
        path: "/cereza-organization/performance-center/OKRs",
        permissions: [],
      },
      {
        name: "Autoevaluaciones",
        path: "/cereza-organization/performance-center/self-Assessments",
        permissions: [],
      },
      {
        name: "MatrizDeHabilidades",
        path: "/cereza-organization/performance-center/skill-matrix",
        permissions: [],
      },
      {
        name: "Evaluaciones360",
        path: "/cereza-organization/performance-center/360-evaluations",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/performance-center/performance-center-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "Activos",
    path: "/cereza-organization/Activos",
    tree: [
      {
        name: "ActivosFisicos",
        path: "/cereza-organization/assets/physical-assets",
        permissions: [
          "add_assets",
          "change_assets",
          "delete_assets",
          "view_assets",
        ],
      },
      {
        name: "ActivosVirtuales",
        path: "/cereza-organization/assets/virtual-assets",
        permissions: [],
      },
      {
        name: "Responsabilidades",
        path: "/cereza-organization/assets/responsibilities",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/assets/assets-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "Recursos",
    path: "/cereza-organization/Recursos",
    tree: [
      {
        name: "Gestión",
        path: "/cereza-organization/resources/management",
        permissions: [],
      },
      {
        name: "visitors",
        path: "/cereza-organization/resources/visitors",
        permissions: [],
      },
      {
        name: "maintenance",
        path: "/cereza-organization/resources/maintenance",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/resources/maintenance-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "Herramientas",
    path: "/cereza-organization/Herramientas",
    tree: [
      {
        name: "Documentos",
        path: "/cereza-organization/tools/documents",
        permissions: [],
      },
      {
        name: "Junta",
        path: "/cereza-organization/tools/board",
        permissions: [],
      },
      {
        name: "InsigniaYLogro",
        path: "/cereza-organization/tools/badge-achievement",
        permissions: [],
      },
      {
        name: "Boleto",
        path: "/cereza-organization/tools/tickets",
        permissions: [],
      },
      {
        name: "Ajustes",
        path: "/cereza-organization/tools/tickets-settings",
        permissions: [],
      },
    ],
  },
  {
    name: "Configuraciones",
    path: "/cereza-organization/Configuraciones",
    tree: [
      {
        name: "Ajustes",
        path: "/cereza-organization/settings/configurations",
        tree: [
          {
            name: "Ajustes-EnvíoCorreo",
            path: "/cereza-organization/settings/configurations/EnvíoCorreo",
            permissions: [
              "add_emailSettings",
              "change_emailSettings",
              "delete_emailSettings",
              "view_emailSettings",
            ],
          },
          {
            name: "Ajustes-Tags",
            path: "/cereza-organization/settings/configurations/Tags",
            permissions: [
              "add_tags",
              "change_tags",
              "delete_tags",
              "view_tags",
            ],
          },
        ],
      },
      {
        name: "Templates",
        path: "/cereza-organization/settings/templates",
        permissions: [
          "add_templates",
          "change_templates",
          "delete_templates",
          "view_templates",
        ],
      },
      {
        name: "ImportExport",
        path: "/cereza-organization/settings/import-export",
        permissions: [],
      },
      {
        name: "Security",
        path: "/cereza-organization/settings/security",
        tree: [
          {
            name: "Security-Access",
            path: "/cereza-organization/settings/security",
            permissions: [],
          },
          {
            name: "Security-Groups",
            path: "/cereza-organization/settings/security-groups",
            permissions: [
              "add_groups",
              "change_groups",
              "delete_groups",
              "view_groups",
            ],
          },
          {
            name: "Security-User-Groups",
            path: "/cereza-organization/settings/security-user-groups",
            permissions: [
              "add_userGroups",
              "change_userGroups",
              "delete_userGroups",
              "view_userGroups",
            ],
          },
          {
            name: "Security-User-Permissions",
            path: "/cereza-organization/settings/security-user-permissions",
            permissions: [
              "add_userPermissions",
              "change_userPermissions",
              "delete_userPermissions",
              "view_userPermissions",
            ],
          },
          {
            name: "Security-Members",
            path: "/cereza-organization/settings/security-user-permissions-members",
            permissions: [],
          },
          {
            name: "Security-Mix",
            path: "/cereza-organization/settings/security-user-permissions-mix",
            permissions: [],
          },
        ],
      },
    ],
  },
  {
    name: "RutasEspeciales",
    path: "/cereza-organization/RutasEspeciales",
    tree: [
      {
        name: "SearchAdvanced",
        path: "/cereza-organization/search/advanced",
        permissions: [],
      },
    ],
  },
];
